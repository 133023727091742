<template>
  <b-overlay :show="showLoadingIndicator" rounded="sm" variant="dark">
    <b-container>
      <b-tabs
        vertical
        nav-wrapper-class="col-3"
        nav-class="mt-5"
        v-model="tabIndex"
      >
        <template #tabs-start>
          <h3>{{ $t('settings.settings') }}</h3>
        </template>
        <b-tab>
          <template #title>{{ $t('settings.general.tabs.profile') }}</template>
          <b-row class="mb-4 mt-5">
            <b-col cols="12" offset-sm="1" sm="10">
              <h3>{{ $t('settings.general.profile.headline') }}</h3>
              <hr>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12" offset-sm="1" sm="3">
              <label>{{ $t('settings.general.profile.email') }}</label>
            </b-col>
            <b-col cols="12" sm="7">
              <b-form-input
                id="user-email"
                v-model="newEmail"
                @update="changedEmail"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row v-if="sentNewEmailRequest" class="mb-4">
            <b-col cols="12" offset-sm="4" sm="7">
              <verification-code-msg
                  :email="newEmail"
                  :updateEmail="sentNewEmailRequest"
                  @verification-success="verificationNumberEntered"
              ></verification-code-msg>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12" offset-sm="1" sm="3">
              <label>{{ $t('settings.general.profile.name') }}</label>
            </b-col>
            <b-col cols="12" sm="7">
              <b-form-input
                  id="user-name"
                  v-model="newName"
                  @update="changedName"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12" offset-sm="1" sm="3">
              <label>{{ $t('settings.general.profile.profileImage') }}</label>
            </b-col>
            <b-col cols="12" sm="7">
              <image-upload
                v-if="profileImage"
                :image-object="profileImage"
                @upload-image-object="changedProfileImageObject"
              />
              <b-form-text
                v-html="$t('settings.general.profile.profileImageDesc')"
              ></b-form-text>
            </b-col>
          </b-row>
          <user-language
            v-if="getUserDataFromDB"
            :language-id="userLanguageId"
            @language-changed="changedLanguage"
          />
          <b-row v-if="mfaIsEnabled !== null" class="mb-4">
            <b-col cols="12" offset-sm="1" sm="3">
              <label>{{ $t('settings.general.profile.twoFactor') }}</label>
            </b-col>
            <b-col v-if="mfaIsEnabled" cols="12" sm="7">
              <div>
                <b-badge pill variant="success">{{ $t('settings.general.profile.statusActive') }}
                </b-badge>
              </div>
              <div class="mt-3">
                <b-button
                  class="mr-2"
                  size="sm"
                  @click="showDisable2Factor = true;">
                  {{ $t('settings.general.profile.disable') }}
                </b-button>
                <disable-two-factor-auth
                  v-if="showDisable2Factor && authUser"
                  :auth-user="authUser"
                  @cancel-two-factor="showDisable2Factor = false;"
                  @disable-two-factor="disableTwoFactor"
                />
              </div>
            </b-col>
            <b-col v-else  cols="12" sm="7">
              <div>
                <b-badge pill disabled variant="info">
                  {{ $t('settings.general.profile.statusInactive') }}
                </b-badge>
              </div>
              <div class="mt-3">
                <b-button size="sm" @click="showEnable2Factor = true;">
                  {{ $t('settings.general.profile.enable') }}</b-button>
                <enable-two-factor-auth
                  v-if="showEnable2Factor && authUser"
                  :auth-user="authUser"
                  @cancel-two-factor="showEnable2Factor = false;"
                  @enable-two-factor="enableTwoFactor"
                />
              </div>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12" offset-sm="1" sm="3">
              <label>{{ $t('settings.general.profile.cookieSettings') }}</label>
            </b-col>
            <b-col cols="12" sm="7">
              <div>
                <b-button size="sm" @click="openCCM19">
                  {{ $t('settings.general.profile.showCookieBanner') }}</b-button>
              </div>
            </b-col>
          </b-row>

        </b-tab>
        <b-tab>
          <template #title>{{ $t('settings.general.tabs.password') }}</template>
          <b-form
            class="mt-5"
            @submit.stop.prevent="onSubmit"
          >
            <b-row class="mb-4">
              <b-col cols="12" offset-sm="1" sm="10">
                <h3>{{ $t('settings.general.password.changePassword') }}</h3>
                <hr>
              </b-col>
            </b-row>
            <b-row class="mb-4">
              <b-col cols="12" offset-sm="1" sm="3">
                <label>{{ $t('settings.general.password.currentPassword') }}</label>
              </b-col>
              <b-col cols="12" sm="7">
                <b-form-group id="password-group" class="position-relative">
                  <b-form-input
                    id="password-input"
                    name="password-input"
                    :type="showPassword ? 'text' : 'password'"
                    @input="resetCheckPasswordValidator"
                    v-model.trim="$v.form.password.$model"
                    :state="validateState('password')"
                    aria-describedby="password-live-feedback"
                  ></b-form-input>
                  <b-icon
                    v-if="!showPassword"
                    icon="eye-slash"
                    class="position-absolute password-eye"
                    aria-hidden="true"
                    scale="1"
                    @click="togglePasswordVisibility(true)"
                  ></b-icon>
                  <b-icon
                    v-else
                    icon="eye"
                    class="position-absolute password-eye"
                    aria-hidden="true"
                    scale="1"
                    @click="togglePasswordVisibility(false)"
                  ></b-icon>

                  <b-form-invalid-feedback id="password-live-feedback">
                    <div class="error" v-if="!$v.form.password.required">
                      {{ $t('settings.general.password.enterCurrentPassword') }}
                    </div>
                    <div class="error" v-if="!$v.form.password.checkPassword">
                      {{ $t('settings.general.password.wrongPassword') }}
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-4">
              <b-col cols="12" offset-sm="1" sm="3">
                <label>{{ $t('settings.general.password.newPassword') }}</label>
              </b-col>
              <b-col cols="12" sm="7">
                <b-form-group id="new-password-group" class="position-relative">
                  <b-form-input
                    id="new-password-input"
                    name="new-password-input"
                    :type="showConfirmPassword ? 'text' : 'password'"
                    v-model.trim="$v.form.newPassword.$model"
                    :state="validateState('newPassword')"
                    aria-describedby="new-password-live-feedback"
                  ></b-form-input>
                  <b-icon
                    v-if="!showConfirmPassword"
                    icon="eye-slash"
                    class="position-absolute password-eye"
                    aria-hidden="true"
                    scale="1"
                    @click="togglePasswordConfirmVisibility(true)"
                  ></b-icon>
                  <b-icon
                    v-else
                    icon="eye"
                    class="position-absolute password-eye"
                    aria-hidden="true"
                    scale="1"
                    @click="togglePasswordConfirmVisibility(false)"
                  ></b-icon>

                  <b-form-invalid-feedback id="new-password-live-feedback">
                    <div class="error" v-if="!$v.form.newPassword.required">
                      {{ $t('settings.general.password.enterPassword') }}
                    </div>
                    <div class="error" v-if="!$v.form.newPassword.minLength">
                      Das Passwort muss mindestens {{ $v.form.newPassword.$params.minLength.min }}
                      Zeichen haben.
                    </div>
                    <div class="error" v-if="!$v.form.newPassword.letterValidation">
                      {{ $t('register.letterRequired') }}
                    </div>
                    <div class="error" v-if="!$v.form.newPassword.numberValidation">
                      {{ $t('register.numberRequired') }}
                    </div>
                    <div class="error" v-if="!$v.form.newPassword.specialCharValidation">
                      {{ $t('register.specialCharRequired') }}
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-4">
              <b-col cols="12" offset-sm="1" sm="3">
                <label>{{ $t('settings.general.password.confirmNewPassword') }}</label>
              </b-col>
              <b-col cols="12" sm="7">
                <b-form-group id="confirm-new-password-group" class="position-relative">
                  <b-form-input
                    id="confirm-new-password-input"
                    name="confirm-new-password-input"
                    :type="showRightPassword ? 'text' : 'password'"
                    v-model.trim="$v.form.confirmNewPassword.$model"
                    :state="validateState('confirmNewPassword')"
                    aria-describedby="confirm-new-password-live-feedback"
                  ></b-form-input>
                  <b-icon
                    v-if="!showRightPassword"
                    icon="eye-slash"
                    class="position-absolute password-eye"
                    aria-hidden="true"
                    scale="1"
                    @click="togglePasswordRightVisibility(true)"
                  ></b-icon>
                  <b-icon
                    v-else
                    icon="eye"
                    class="position-absolute password-eye"
                    aria-hidden="true"
                    scale="1"
                    @click="togglePasswordRightVisibility(false)"
                  ></b-icon>

                  <b-form-invalid-feedback id="confirm-new-password-live-feedback">
                    <div class="error" v-if="!$v.form.confirmNewPassword.sameAsPassword">
                      Die neu eingegebenen Passwörter stimmen nicht überein!
                    </div>
                    <div class="error" v-if="!$v.form.confirmNewPassword.required">
                      Dieses Feld darf nicht leer sein!
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" offset-sm="4" sm="7">
                <b-button block type="submit" variant="success" :disabled="$v.form.$invalid">
                  {{ $t('settings.general.password.changePassword') }}
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" offset-sm="4" sm="7">
                <p class="text-center mt-4">
                  <small>{{ $t('settings.general.password.forgotPassword') }}</small>
                  <small>
                    <b-link to="/webmag-password-reset">
                      {{ $t('settings.general.password.resetPassword') }}
                    </b-link>
                  </small>
                </p>
              </b-col>
            </b-row>
          </b-form>
        </b-tab>
      </b-tabs>
      <modal-leave-page ref="leaveModalWindow"/>
    </b-container>
  </b-overlay>
</template>

<script>
import UserLanguage from '@/components/settings/user/UserLanguage.vue';
import SetShowHeaderInCache from '@/graphQlQueries/mutations/setShowHeaderInCache';
import UpdateUserEmail from '@/graphQlQueries/mutations/updateUserEmail';
import UpdateUserName from '@/graphQlQueries/mutations/updateUserName';
import UpdateUserLanguage from '@/graphQlQueries/mutations/updateUserLanguage';
import GetDataHasChangedForSavingFromCache from '@/graphQlQueries/queries/getDataHasChangedForSavingFromCache';
import GetUserDataByEmail from '@/graphQlQueries/queries/getUserDataByEmail';
import BeforeLeavingRouteOpenModal from '@/mixins/beforeLeavingRouteOpenModal';
import SetDataChangedInCache from '@/mixins/setDataChangedInCache';
import UpdateProfilePicture from '@/graphQlQueries/mutations/updateProfilePicture';
import { Auth } from '@aws-amplify/auth';
import Login from '@/mixins/login';
import SetPageTypeInCache from '@/graphQlQueries/mutations/setPageTypeInCache';
import UserDataAndAccess from '@/mixins/userDataAndAccess';
import { validationMixin } from 'vuelidate';
import { minLength, required, sameAs } from 'vuelidate/lib/validators';
import { isEqual } from 'lodash';

const checkPassword = (value, vm) => vm.rightPassword;
const specialChars = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
const specialCharValidation = (value) => specialChars.test(value);
const numberValidation = (value) => /\d/.test(value);
const letterValidation = (value) => /[a-z]/.test(value);

export default {
  name: 'GeneralSettings',
  mixins: [
    BeforeLeavingRouteOpenModal,
    Login,
    UserDataAndAccess,
    validationMixin,
    SetDataChangedInCache,
  ],
  components: {
    UserLanguage,
    ModalLeavePage: () => import('@/components/modals/ModalLeavePage.vue'),
    ImageUpload: () => import('@/components/helper/ImageUpload.vue'),
    VerificationCodeMsg: () => import('@/components/messages/VerificationCode.vue'),
    EnableTwoFactorAuth: () => import('@/components/settings/user/EnableTwoFactorAuth.vue'),
    DisableTwoFactorAuth: () => import('@/components/settings/user/DisableTwoFactorAuth.vue'),
  },
  data() {
    return {
      getUserDataFromDB: null,
      showOverlay: true,
      showEnable2Factor: false,
      showDisable2Factor: false,
      mfaIsEnabled: null,
      authUser: null,
      pageType: 'general-settings',
      email: null,
      newEmail: null,
      name: null,
      newName: null,
      sentNewEmailRequest: false,
      userLanguageId: null,
      originalUserLanguageId: null,
      tabIndex: 0,
      showPassword: false,
      showConfirmPassword: false,
      showRightPassword: false,
      form: {
        password: null,
        newPassword: null,
        confirmNewPassword: null,
        rightPassword: true,
      },
      originalProfileImage: null,
      profileImage: null,
      defaultEmptyImageObject: {
        url: null,
        focalpoint: {
          x: 50,
          y: 50,
        },
        width: null,
        height: null,
      },
    };
  },
  computed: {
    showLoadingIndicator() {
      if (this.$apollo.loading) {
        return true;
      }
      return this.showOverlay;
    },
  },
  validations: {
    form: {
      password: {
        required,
        checkPassword,
      },
      newPassword: {
        required,
        minLength: minLength(8),
        specialCharValidation,
        letterValidation,
        numberValidation,
      },
      confirmNewPassword: {
        required,
        sameAsPassword: sameAs('newPassword'),
      },
    },
  },
  apollo: {
    getUserDataFromDB: {
      query: GetUserDataByEmail,
      variables() {
        return {
          email: this.email,
        };
      },
      update(data) {
        if (data?.users[0]?.profile_pictures) {
          this.profileImage = data.users[0].profile_pictures;
          this.originalProfileImage = { ...data.users[0].profile_pictures };
        } else {
          this.profileImage = { ...this.defaultEmptyImageObject };
        }
        if (data?.users[0]?.language_id) {
          this.userLanguageId = data.users[0].language_id;
          this.originalUserLanguageId = data.users[0].language_id;
        }
        if (data?.users[0]?.full_name) {
          this.name = data.users[0].full_name;
          this.newName = data.users[0].full_name;
        }
        return true;
      },
      skip() {
        return !this.email;
      },
      fetchPolicy: 'network-only',
      error(error) {
        console.error('DB Error occured', error, this.$currentUserRole);
      },
    },
    hasDataForSavingChangedObject: {
      query: GetDataHasChangedForSavingFromCache,
      update(data) {
        if (data.dataHasChangedForSaving) {
          if (data.dataHasChangedForSaving.isSubmittedButtonPressed) {
            // set allowQueryOfPublishData so that the publish Query is triggered
            this.updateProfileSettings();
            this.updateLanguageSettings();
            this.updateEmailSettings();
            this.updateNameSettings();
          } else if (data.dataHasChangedForSaving.isCancelButtonPressed) {
            this.resetSettings();
          }
        }
        return data;
      },
    },
  },
  async created() {
    this.setTypeOfPageInApolloCache();
    this.authUser = await Auth.currentAuthenticatedUser();
    this.email = this.authUser.attributes.email;
    this.newEmail = this.authUser.attributes.email;
    this.showOverlay = false;
    this.mfaIsEnabled = this.authUser.preferredMFA === 'SOFTWARE_TOKEN_MFA';
  },
  methods: {
    enableTwoFactor() {
      this.showEnable2Factor = false;
      this.mfaIsEnabled = true;
    },
    disableTwoFactor() {
      this.showDisable2Factor = false;
      this.mfaIsEnabled = false;
    },
    openCCM19() {
      window.CCM.openWidget();
      return false;
    },
    changedEmail() {
      console.log('new email:', this.newEmail);
      let isDataChanged = false;
      if (this.email !== this.newEmail) {
        if (this.newEmail) {
          isDataChanged = true;
        }
      }
      console.log(isDataChanged);
      this.profileSettingsChanged(isDataChanged);
    },
    changedName() {
      console.log('new name:', this.newName);
      let isDataChanged = false;
      if (this.name !== this.newName) {
        if (this.newName) {
          isDataChanged = true;
        }
      }
      console.log(isDataChanged);
      this.profileSettingsChanged(isDataChanged);
    },
    changedProfileImageObject(imageObject) {
      let isDataChanged = false;
      // check the changes with the original data
      if (!isEqual(this.originalProfileImage, imageObject)) {
        if (imageObject || this.originalProfileImage.url) {
          isDataChanged = true;
        }
      }
      this.profileImage = imageObject;
      this.profileSettingsChanged(isDataChanged);
    },
    changedLanguage(languageItem) {
      let isDataChanged = false;
      this.userLanguageId = languageItem.id;
      if (this.userLanguageId !== this.originalUserLanguageId) {
        isDataChanged = true;
      }
      this.profileSettingsChanged(isDataChanged);
    },
    profileSettingsChanged(isDataChanged) {
      this.setDataChangedInCache({
        pageType: this.pageType,
        isDataChanged,
        isSubmittedButtonPressed: false,
        isCancelButtonPressed: false,
        isDataPublished: false,
      });
    },
    showLeaveModal() {
      this.$refs.leaveModalWindow.$refs.leaveModal.show();
    },
    async updateEmailSettings() {
      try {
        this.showOverlay = true;
        await Auth.updateUserAttributes(this.authUser, { email: this.newEmail });
        this.showOverlay = false;
      } catch (err) {
        console.error(err);
        console.log(err.code);
        this.showOverlay = false;
      }
      if (this.newEmail && this.email !== this.newEmail) {
        this.sentNewEmailRequest = true;
      }
      await this.resetCacheForHeaderButtons();
    },
    async verificationNumberEntered(status) {
      this.showOverlay = true;
      if (status) {
        await this.$apollo.mutate({
          mutation: UpdateUserEmail,
          variables: {
            email: this.email,
            newEmail: this.newEmail,
            email_confirmed: true,
          },
        });
        this.sentNewEmailRequest = false;
        try {
          await Auth.signOut({ global: true });
          this.$currentUserRole = 'user';
          await this.$router.push({ path: '/webmag-login', query: { emailChanged: 'true' } });
        } catch (error) {
          console.error('Logout was not successful', error);
        }
        this.showOverlay = false;
      }
    },
    async updateNameSettings() {
      await this.$apollo.mutate({
        mutation: UpdateUserName,
        variables: {
          email: this.email,
          newName: this.newName,
        },
      });
      await this.resetCacheForHeaderButtons();
    },
    async updateLanguageSettings() {
      await this.$apollo.mutate({
        mutation: UpdateUserLanguage,
        variables: {
          email: this.email,
          languageId: this.userLanguageId,
        },
      });
      await this.resetCacheForHeaderButtons();
    },
    updateProfileSettings() {
      this.$apollo.mutate({
        mutation: UpdateProfilePicture,
        variables: {
          email: this.email,
          profileImageObject: this.profileImage,
        },
      });
      this.resetCacheForHeaderButtons();
    },
    resetSettings() {
      this.resetCacheForHeaderButtons();
    },
    async resetCacheForHeaderButtons() {
      this.setDataChangedInCache({
        pageType: this.pageType,
        isDataChanged: false,
        isSubmittedButtonPressed: false,
        isCancelButtonPressed: false,
        isDataPublished: false,
      });
      await this.$apollo.queries.getUserDataFromDB.refresh();
    },
    setTypeOfPageInApolloCache() {
      this.$apollo.mutate({
        mutation: SetPageTypeInCache,
        variables: {
          type: this.pageType,
        },
      });
      this.$apollo.mutate({
        mutation: SetShowHeaderInCache,
        variables: {
          show: true,
        },
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetCheckPasswordValidator() {
      this.form.rightPassword = true;
    },
    async onSubmit() {
      await this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      try {
        this.showOverlay = true;
        await Auth.changePassword(
          this.authUser, this.form.password, this.form.newPassword,
        );
        await Auth.signOut({ global: true });
        await Auth.signIn(this.email, this.form.newPassword);

        this.form.password = '';
        this.form.newPassword = '';
        this.form.confirmNewPassword = '';
        this.$nextTick(() => {
          this.$v.$reset();
        });
        this.showOverlay = false;
      } catch (err) {
        if (err.code === 'auth/wrong-password') {
          this.form.rightPassword = false;
        }
        console.error(err);
        console.log(err.code);
        this.showOverlay = false;
      }
    },
    togglePasswordVisibility(value) {
      this.showPassword = !!value;
    },
    togglePasswordConfirmVisibility(value) {
      this.showConfirmPassword = !!value;
    },
    togglePasswordRightVisibility(value) {
      this.showRightPassword = !!value;
    },
  },
};
</script>

<style scoped lang="scss">
.password-eye {
  right: 10px;
  top: 12px;
  cursor: pointer;
}

.is-valid ~ .password-eye,
.is-invalid ~ .password-eye {
  right: 35px;
}
</style>
