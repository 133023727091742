const beforeLeavingRouteOpenModal = {
  beforeRouteLeave(to, from, next) {
    const { isDataChanged } = this.hasDataForSavingChangedObject?.dataHasChangedForSaving;
    if (isDataChanged) {
      this.showLeaveModal();
      this.$nextTick(() => {
        const modalRef = this.$refs.leaveModalWindow.$refs.leaveModal;
        // Set up hide handler.
        // Done in next tick to ensure Modal is in DOM
        modalRef.$once('hide', (bvEvt) => {
          if (bvEvt.trigger === 'ok') {
            // now we have to modify the isDataChanged attribute and reset the folder settings
            this.resetSettings();
            this.setDataChangedInCache({
              pageType: this.pageType,
              isDataChanged: false,
              isSubmittedButtonPressed: false,
              isCancelButtonPressed: false,
              isDataPublished: false,
            });
            modalRef.$once('hidden', () => {
              next();
            });
          } else {
            // User clicked cancel button
            next(false);
          }
        });
      });
    } else {
      next();
    }
  },
};

export default beforeLeavingRouteOpenModal;
