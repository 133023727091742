var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-overlay",
    {
      attrs: { show: _vm.showLoadingIndicator, rounded: "sm", variant: "dark" }
    },
    [
      _c(
        "b-container",
        [
          _c(
            "b-tabs",
            {
              attrs: {
                vertical: "",
                "nav-wrapper-class": "col-3",
                "nav-class": "mt-5"
              },
              scopedSlots: _vm._u([
                {
                  key: "tabs-start",
                  fn: function() {
                    return [
                      _c("h3", [_vm._v(_vm._s(_vm.$t("settings.settings")))])
                    ]
                  },
                  proxy: true
                }
              ]),
              model: {
                value: _vm.tabIndex,
                callback: function($$v) {
                  _vm.tabIndex = $$v
                },
                expression: "tabIndex"
              }
            },
            [
              _c(
                "b-tab",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function() {
                        return [
                          _vm._v(
                            _vm._s(_vm.$t("settings.general.tabs.profile"))
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c(
                    "b-row",
                    { staticClass: "mb-4 mt-5" },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", "offset-sm": "1", sm: "10" } },
                        [
                          _c("h3", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("settings.general.profile.headline")
                              )
                            )
                          ]),
                          _c("hr")
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mb-4" },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", "offset-sm": "1", sm: "3" } },
                        [
                          _c("label", [
                            _vm._v(
                              _vm._s(_vm.$t("settings.general.profile.email"))
                            )
                          ])
                        ]
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", sm: "7" } },
                        [
                          _c("b-form-input", {
                            attrs: { id: "user-email" },
                            on: { update: _vm.changedEmail },
                            model: {
                              value: _vm.newEmail,
                              callback: function($$v) {
                                _vm.newEmail = $$v
                              },
                              expression: "newEmail"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.sentNewEmailRequest
                    ? _c(
                        "b-row",
                        { staticClass: "mb-4" },
                        [
                          _c(
                            "b-col",
                            {
                              attrs: { cols: "12", "offset-sm": "4", sm: "7" }
                            },
                            [
                              _c("verification-code-msg", {
                                attrs: {
                                  email: _vm.newEmail,
                                  updateEmail: _vm.sentNewEmailRequest
                                },
                                on: {
                                  "verification-success":
                                    _vm.verificationNumberEntered
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-row",
                    { staticClass: "mb-4" },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", "offset-sm": "1", sm: "3" } },
                        [
                          _c("label", [
                            _vm._v(
                              _vm._s(_vm.$t("settings.general.profile.name"))
                            )
                          ])
                        ]
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", sm: "7" } },
                        [
                          _c("b-form-input", {
                            attrs: { id: "user-name" },
                            on: { update: _vm.changedName },
                            model: {
                              value: _vm.newName,
                              callback: function($$v) {
                                _vm.newName = $$v
                              },
                              expression: "newName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mb-4" },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", "offset-sm": "1", sm: "3" } },
                        [
                          _c("label", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("settings.general.profile.profileImage")
                              )
                            )
                          ])
                        ]
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", sm: "7" } },
                        [
                          _vm.profileImage
                            ? _c("image-upload", {
                                attrs: { "image-object": _vm.profileImage },
                                on: {
                                  "upload-image-object":
                                    _vm.changedProfileImageObject
                                }
                              })
                            : _vm._e(),
                          _c("b-form-text", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$t(
                                  "settings.general.profile.profileImageDesc"
                                )
                              )
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.getUserDataFromDB
                    ? _c("user-language", {
                        attrs: { "language-id": _vm.userLanguageId },
                        on: { "language-changed": _vm.changedLanguage }
                      })
                    : _vm._e(),
                  _vm.mfaIsEnabled !== null
                    ? _c(
                        "b-row",
                        { staticClass: "mb-4" },
                        [
                          _c(
                            "b-col",
                            {
                              attrs: { cols: "12", "offset-sm": "1", sm: "3" }
                            },
                            [
                              _c("label", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("settings.general.profile.twoFactor")
                                  )
                                )
                              ])
                            ]
                          ),
                          _vm.mfaIsEnabled
                            ? _c("b-col", { attrs: { cols: "12", sm: "7" } }, [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "b-badge",
                                      {
                                        attrs: { pill: "", variant: "success" }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "settings.general.profile.statusActive"
                                            )
                                          ) + " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "mt-3" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "mr-2",
                                        attrs: { size: "sm" },
                                        on: {
                                          click: function($event) {
                                            _vm.showDisable2Factor = true
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "settings.general.profile.disable"
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _vm.showDisable2Factor && _vm.authUser
                                      ? _c("disable-two-factor-auth", {
                                          attrs: { "auth-user": _vm.authUser },
                                          on: {
                                            "cancel-two-factor": function(
                                              $event
                                            ) {
                                              _vm.showDisable2Factor = false
                                            },
                                            "disable-two-factor":
                                              _vm.disableTwoFactor
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ])
                            : _c("b-col", { attrs: { cols: "12", sm: "7" } }, [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "b-badge",
                                      {
                                        attrs: {
                                          pill: "",
                                          disabled: "",
                                          variant: "info"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "settings.general.profile.statusInactive"
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "mt-3" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: { size: "sm" },
                                        on: {
                                          click: function($event) {
                                            _vm.showEnable2Factor = true
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "settings.general.profile.enable"
                                              )
                                            )
                                        )
                                      ]
                                    ),
                                    _vm.showEnable2Factor && _vm.authUser
                                      ? _c("enable-two-factor-auth", {
                                          attrs: { "auth-user": _vm.authUser },
                                          on: {
                                            "cancel-two-factor": function(
                                              $event
                                            ) {
                                              _vm.showEnable2Factor = false
                                            },
                                            "enable-two-factor":
                                              _vm.enableTwoFactor
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-row",
                    { staticClass: "mb-4" },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", "offset-sm": "1", sm: "3" } },
                        [
                          _c("label", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "settings.general.profile.cookieSettings"
                                )
                              )
                            )
                          ])
                        ]
                      ),
                      _c("b-col", { attrs: { cols: "12", sm: "7" } }, [
                        _c(
                          "div",
                          [
                            _c(
                              "b-button",
                              {
                                attrs: { size: "sm" },
                                on: { click: _vm.openCCM19 }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "settings.general.profile.showCookieBanner"
                                      )
                                    )
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-tab",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function() {
                        return [
                          _vm._v(
                            _vm._s(_vm.$t("settings.general.tabs.password"))
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c(
                    "b-form",
                    {
                      staticClass: "mt-5",
                      on: {
                        submit: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.onSubmit.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _c(
                        "b-row",
                        { staticClass: "mb-4" },
                        [
                          _c(
                            "b-col",
                            {
                              attrs: { cols: "12", "offset-sm": "1", sm: "10" }
                            },
                            [
                              _c("h3", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "settings.general.password.changePassword"
                                    )
                                  )
                                )
                              ]),
                              _c("hr")
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "mb-4" },
                        [
                          _c(
                            "b-col",
                            {
                              attrs: { cols: "12", "offset-sm": "1", sm: "3" }
                            },
                            [
                              _c("label", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "settings.general.password.currentPassword"
                                    )
                                  )
                                )
                              ])
                            ]
                          ),
                          _c(
                            "b-col",
                            { attrs: { cols: "12", sm: "7" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "position-relative",
                                  attrs: { id: "password-group" }
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "password-input",
                                      name: "password-input",
                                      type: _vm.showPassword
                                        ? "text"
                                        : "password",
                                      state: _vm.validateState("password"),
                                      "aria-describedby":
                                        "password-live-feedback"
                                    },
                                    on: {
                                      input: _vm.resetCheckPasswordValidator
                                    },
                                    model: {
                                      value: _vm.$v.form.password.$model,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.$v.form.password,
                                          "$model",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "$v.form.password.$model"
                                    }
                                  }),
                                  !_vm.showPassword
                                    ? _c("b-icon", {
                                        staticClass:
                                          "position-absolute password-eye",
                                        attrs: {
                                          icon: "eye-slash",
                                          "aria-hidden": "true",
                                          scale: "1"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.togglePasswordVisibility(
                                              true
                                            )
                                          }
                                        }
                                      })
                                    : _c("b-icon", {
                                        staticClass:
                                          "position-absolute password-eye",
                                        attrs: {
                                          icon: "eye",
                                          "aria-hidden": "true",
                                          scale: "1"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.togglePasswordVisibility(
                                              false
                                            )
                                          }
                                        }
                                      }),
                                  _c(
                                    "b-form-invalid-feedback",
                                    { attrs: { id: "password-live-feedback" } },
                                    [
                                      !_vm.$v.form.password.required
                                        ? _c("div", { staticClass: "error" }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "settings.general.password.enterCurrentPassword"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ])
                                        : _vm._e(),
                                      !_vm.$v.form.password.checkPassword
                                        ? _c("div", { staticClass: "error" }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "settings.general.password.wrongPassword"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "mb-4" },
                        [
                          _c(
                            "b-col",
                            {
                              attrs: { cols: "12", "offset-sm": "1", sm: "3" }
                            },
                            [
                              _c("label", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "settings.general.password.newPassword"
                                    )
                                  )
                                )
                              ])
                            ]
                          ),
                          _c(
                            "b-col",
                            { attrs: { cols: "12", sm: "7" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "position-relative",
                                  attrs: { id: "new-password-group" }
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "new-password-input",
                                      name: "new-password-input",
                                      type: _vm.showConfirmPassword
                                        ? "text"
                                        : "password",
                                      state: _vm.validateState("newPassword"),
                                      "aria-describedby":
                                        "new-password-live-feedback"
                                    },
                                    model: {
                                      value: _vm.$v.form.newPassword.$model,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.$v.form.newPassword,
                                          "$model",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "$v.form.newPassword.$model"
                                    }
                                  }),
                                  !_vm.showConfirmPassword
                                    ? _c("b-icon", {
                                        staticClass:
                                          "position-absolute password-eye",
                                        attrs: {
                                          icon: "eye-slash",
                                          "aria-hidden": "true",
                                          scale: "1"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.togglePasswordConfirmVisibility(
                                              true
                                            )
                                          }
                                        }
                                      })
                                    : _c("b-icon", {
                                        staticClass:
                                          "position-absolute password-eye",
                                        attrs: {
                                          icon: "eye",
                                          "aria-hidden": "true",
                                          scale: "1"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.togglePasswordConfirmVisibility(
                                              false
                                            )
                                          }
                                        }
                                      }),
                                  _c(
                                    "b-form-invalid-feedback",
                                    {
                                      attrs: {
                                        id: "new-password-live-feedback"
                                      }
                                    },
                                    [
                                      !_vm.$v.form.newPassword.required
                                        ? _c("div", { staticClass: "error" }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "settings.general.password.enterPassword"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ])
                                        : _vm._e(),
                                      !_vm.$v.form.newPassword.minLength
                                        ? _c("div", { staticClass: "error" }, [
                                            _vm._v(
                                              " Das Passwort muss mindestens " +
                                                _vm._s(
                                                  _vm.$v.form.newPassword
                                                    .$params.minLength.min
                                                ) +
                                                " Zeichen haben. "
                                            )
                                          ])
                                        : _vm._e(),
                                      !_vm.$v.form.newPassword.letterValidation
                                        ? _c("div", { staticClass: "error" }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "register.letterRequired"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ])
                                        : _vm._e(),
                                      !_vm.$v.form.newPassword.numberValidation
                                        ? _c("div", { staticClass: "error" }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "register.numberRequired"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ])
                                        : _vm._e(),
                                      !_vm.$v.form.newPassword
                                        .specialCharValidation
                                        ? _c("div", { staticClass: "error" }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "register.specialCharRequired"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "mb-4" },
                        [
                          _c(
                            "b-col",
                            {
                              attrs: { cols: "12", "offset-sm": "1", sm: "3" }
                            },
                            [
                              _c("label", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "settings.general.password.confirmNewPassword"
                                    )
                                  )
                                )
                              ])
                            ]
                          ),
                          _c(
                            "b-col",
                            { attrs: { cols: "12", sm: "7" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "position-relative",
                                  attrs: { id: "confirm-new-password-group" }
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "confirm-new-password-input",
                                      name: "confirm-new-password-input",
                                      type: _vm.showRightPassword
                                        ? "text"
                                        : "password",
                                      state: _vm.validateState(
                                        "confirmNewPassword"
                                      ),
                                      "aria-describedby":
                                        "confirm-new-password-live-feedback"
                                    },
                                    model: {
                                      value:
                                        _vm.$v.form.confirmNewPassword.$model,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.$v.form.confirmNewPassword,
                                          "$model",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression:
                                        "$v.form.confirmNewPassword.$model"
                                    }
                                  }),
                                  !_vm.showRightPassword
                                    ? _c("b-icon", {
                                        staticClass:
                                          "position-absolute password-eye",
                                        attrs: {
                                          icon: "eye-slash",
                                          "aria-hidden": "true",
                                          scale: "1"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.togglePasswordRightVisibility(
                                              true
                                            )
                                          }
                                        }
                                      })
                                    : _c("b-icon", {
                                        staticClass:
                                          "position-absolute password-eye",
                                        attrs: {
                                          icon: "eye",
                                          "aria-hidden": "true",
                                          scale: "1"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.togglePasswordRightVisibility(
                                              false
                                            )
                                          }
                                        }
                                      }),
                                  _c(
                                    "b-form-invalid-feedback",
                                    {
                                      attrs: {
                                        id: "confirm-new-password-live-feedback"
                                      }
                                    },
                                    [
                                      !_vm.$v.form.confirmNewPassword
                                        .sameAsPassword
                                        ? _c("div", { staticClass: "error" }, [
                                            _vm._v(
                                              " Die neu eingegebenen Passwörter stimmen nicht überein! "
                                            )
                                          ])
                                        : _vm._e(),
                                      !_vm.$v.form.confirmNewPassword.required
                                        ? _c("div", { staticClass: "error" }, [
                                            _vm._v(
                                              " Dieses Feld darf nicht leer sein! "
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            {
                              attrs: { cols: "12", "offset-sm": "4", sm: "7" }
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    block: "",
                                    type: "submit",
                                    variant: "success",
                                    disabled: _vm.$v.form.$invalid
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "settings.general.password.changePassword"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            {
                              attrs: { cols: "12", "offset-sm": "4", sm: "7" }
                            },
                            [
                              _c("p", { staticClass: "text-center mt-4" }, [
                                _c("small", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "settings.general.password.forgotPassword"
                                      )
                                    )
                                  )
                                ]),
                                _c(
                                  "small",
                                  [
                                    _c(
                                      "b-link",
                                      {
                                        attrs: { to: "/webmag-password-reset" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "settings.general.password.resetPassword"
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("modal-leave-page", { ref: "leaveModalWindow" })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }