import SetDataHasChangedForSavingInCache from '@/graphQlQueries/mutations/setDataHasChangedForSavingInCache';

const setDataChangedInCache = {
  methods: {
    setDataChangedInCache($settingsObject) {
      const {
        pageType,
        isDataChanged,
        isSubmittedButtonPressed,
        isCancelButtonPressed,
        isDataPublished,
      } = $settingsObject;
      this.$apollo.mutate({
        mutation: SetDataHasChangedForSavingInCache,
        variables: {
          pageType,
          isDataChanged,
          isSubmittedButtonPressed,
          isCancelButtonPressed,
          isDataPublished,
        },
      });
    },
  },
};

export default setDataChangedInCache;
