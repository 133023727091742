import gql from 'graphql-tag';

export default gql`
mutation updateProfilePicture($email: String!, $profileImageObject: jsonb) {
  update_users(
    where: { email: {_eq: $email} },
    _set: { profile_pictures: $profileImageObject }
  ) {
    affected_rows
  }
}
`;
