import gql from 'graphql-tag';

export default gql`
mutation updateUserEmail(
  $email: String!,
  $newEmail: String!,
  $email_confirmed: Boolean!,
) {
  update_users(
    where: {
      email: {_eq: $email}
    }, 
    _set: {email: $newEmail, email_confirmed: $email_confirmed}
  ) {
    affected_rows
  }
}
`;
