<template>
  <b-row class="mb-4" v-if="allAvailableLanguages">
    <b-col cols="12" offset-sm="1" sm="3">
      <label>{{ $t('settings.general.profile.userLanguage') }}</label>
    </b-col>
    <b-col cols="12" sm="7">
      <tree-select
        v-model="currentLanguageId"
        :multiple="false"
        :options="allAvailableLanguages"
        :clearable="false"
        :placeholder="$t('settings.general.profile.userLanguagePlaceholder')"
        @select="changeLanguage"
      />
    </b-col>
  </b-row>
</template>

<script>
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import GetAllAvailableLanguages from '@/graphQlQueries/queries/getAllAvailableLanguages';

export default {
  name: 'UserLanguage',
  components: { TreeSelect: () => import('@riophae/vue-treeselect') },
  props: ['languageId'],
  data: () => ({
    // if the languageId is null use the localId of english USA
    currentLanguageId: null,
    languagesQuery: null,
    allAvailableLanguages: null,
    languagesToChooseFrom: [
      'de',
      'en',
    ],
  }),
  created() {
    if (this.languageId) {
      this.currentLanguageId = this.languageId;
    }
    // get language object
  },
  apollo: {
    languagesQuery: {
      query: GetAllAvailableLanguages,
      update(data) {
        console.log('data', data);
        const lang = [];
        data.languages.forEach((language) => {
          if (this.languagesToChooseFrom.includes(language.handle)) {
            lang.push({
              id: language.language_id,
              label: language.name,
              name: language.handle,
            });
          }
        });
        this.allAvailableLanguages = lang;
      },
    },
  },
  methods: {
    async changeLanguage(item) {
      this.$emit('language-changed', item);
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ .vue-treeselect__control {
  background: #5b6064;
  border-color: #000;

  .vue-treeselect__single-value {
    color: #fff;
  }
}
</style>
