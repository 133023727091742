import gql from 'graphql-tag';

export default gql`
query getUserDataByEmail($email: String) {
  users(where: {email: {_eq: $email}}) {
    profile_pictures
    language_id
    full_name
  }
}
`;
