import gql from 'graphql-tag';

export default gql`
mutation(
  $pageType: String,
  $isDataChanged: Boolean!,
  $isSubmittedButtonPressed: Boolean!,
  $isCancelButtonPressed: Boolean!,
  $isDataPublished: Boolean!,
) {
  setDataHasChangedForSaving(
    pageType: $pageType,
    isDataChanged: $isDataChanged,
    isSubmittedButtonPressed: $isSubmittedButtonPressed,
    isCancelButtonPressed: $isCancelButtonPressed,
    isDataPublished: $isDataPublished,
  ) @client
}
`;
