import gql from 'graphql-tag';

export default gql`
mutation updateUserLanguageId(
  $email: String!,
  $languageId: Int!,
) {
  update_users(
    where: {
      email: {_eq: $email}
    }, 
    _set: {language_id: $languageId}
  ) {
    affected_rows
  }
}
`;
